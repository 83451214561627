import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GLOBALS from './globals';

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
    c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
    return c.substring(name.length, c.length);
    }
  }
  return "";
}
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
let uid = getCookie("uid");
if(uid == "") {
  uid = uuidv4()
  document.cookie = "uid="+ uid +"; expires=Thu, 20 Dec 2099 12:00:00 UTC";
}

GLOBALS.uid = uid;

let sides = []
fetch("https://voyaj-6gt6mhdfpa-uc.a.run.app/titles/"+uid, {
    method: "GET",
    headers: {
        "Content-type": "application/json; charset=UTF-8"
    }
}).then((response) => response.json())
.then((json) => {
    console.log(json)
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App sides={sides} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
