import React from "react";
import { Link } from "react-router-dom";
import Icon from './Icon';
import "../scss/Sidebar.scss"
// import { getAuth, signOut } from "firebase/auth";

// function logout() {
//     const auth = getAuth();
//     signOut(auth).then(() => {
//         console.log('Sign out successful');
//     }).catch((error) => {
//         console.log('Sign out failed');
//     });
// }

const SidebarElement = props => {
    // if(props.name == "Logout") {
    //     return (
    //         <Link onClick={logout} >
    //             <div className={"Sidebar-Element " + props.status}>
    //                 <Icon iconName={props.icon} />
    //                 <span>{props.name}</span>
    //             </div>
    //         </Link>
    //     );
    // }
    return (
        <Link to={props.name == "New Search" ? "/" : "/s/" + props.id} >
            <div className={"Sidebar-Element " + props.status} style={props.border == "yes" ? {"border": "1px solid #a76d60", "borderRadius": "3px"} : {}}>
                <Icon iconName={props.icon} />
                <span>{props.name}</span>
            </div>
        </Link>
    );
}

export default SidebarElement;