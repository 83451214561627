import React, {useEffect} from "react";
import Sidebar from "../components/Sidebar";

const Searches = props => {

    // get content
    const content = {}
    const getContent = () => {
        fetch("https://voyaj-6gt6mhdfpa-uc.a.run.app/search/"+props.id, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then((response) => response.json())
        .then((json) => {
            content = json;
        });
    }

    useEffect(() => {
        getContent();
      }, []);
    

    return (
        <div className="Page">
            <Sidebar pages="searches" id={props.id} sides={props.sides} />
            <div className="Content">
                {/* display content */ content.toString()}
            </div>
        </div>
    )
}

export default Searches;