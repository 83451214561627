import './scss/styles.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Search from './pages/search';
import Searches from './pages/searches';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Search sides={[]} />} />
          <Route path="s/:id" element={<Searches sides={[]} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
