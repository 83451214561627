import React, { useState } from "react";
import SidebarElement from "./SidebarElement";
import '../scss/Sidebar.scss'
import Icon from './Icon';
import logo from '../voyaj-favicon.webp';

const Sidebar = props => {
    const [open, setOpen] = useState(true);

    return (
        <div className={`Sidebar-Container Sidebar-Container-${open ? 'open' : 'close'}`}>
            <div className={`Sidebar-${open ? 'open' : 'close'}`}>
                <div className="Sidebar">
                    {/* <div className="Sidebar-Header">
                        <div className="Logo">
                            <img src={logo} alt="logo" />
                        </div>
                        <h3>voyaj</h3>
                        <button className={`Close ${open ? 'Visible' : 'Hidden'}`} onClick={(e) => {setOpen(false)}}>
                            <Icon iconName="BsChevronLeft" />
                        </button>
                        <button className={`Open ${!open ? 'Visible' : 'Hidden'}`} onClick={(e) => {setOpen(true)}}>
                            <Icon iconName="BsChevronRight" />
                        </button>
                    </div> */}

                    <div className="Sidebar-Header">
                        <SidebarElement status={props.page == "search" ? "active-sidebar" : "inactive"} name="New Search" icon="BsPlusLg" border="yes" />
                    </div>
                    
                    <div className="Sidebar-Content">
                        <p>SEARCHES</p>
                        {props.sides.length == 0 ? <p><b>No Searches yet</b></p> : props.sides}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;