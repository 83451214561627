import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Button from 'react-bootstrap/Button';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import globals from "../globals";
import { useNavigate } from 'react-router-dom';

const Search = props => {

    const navigate = useNavigate();

    const [searching, setSearch] = useState(false)
    const onSearch=()=>{
        setSearch(true)
    }

    const [dates,setDate]=useState({"date": new Date(), "date2": new Date()});
    const onDateChange = props => {
        const updatedValues = { "date": props.startDate, "date2": props.endDate };
        setDate(updatedValues);
    }

    const [value,setValue]=useState('');
    const handleSelect=(e)=>{
        setValue(e.target.value)
    }

    const [value2,setValue2]=useState('');
    const handleSelect2=(e)=>{
        setValue2(e.target.value)
    }

    const [value3,setValue3]=useState('');
    const handleSelect3=(e)=>{
        setValue3(e.target.value)
    }

    const [values, setValues] = useState({"from": "", "to": "", "hPrefs": "", "aPrefs": "", "rPrefs": ""});
    const handleValues=(e)=>{
        const id = e.target.id;
        const newValue = e.target.value;
        const updatedValues = { ...values, [id]: newValue };
        setValues(updatedValues);
    }


    const createSearch = async() => {
        // await a python firebase cloud functions server that will return all info
        onSearch();
        fetch("https://voyaj-6gt6mhdfpa-uc.a.run.app/search", {
            method: "POST",
            body: JSON.stringify({
                uid: globals.uid,
                from: values['from'],
                to: values['to'],
                date: dates['date'],
                date2: dates['date2'],
                attendees: value3,
                class: value2,
                budget: value,
                hPrefs: values['hPrefs'],
                aPrefs: values['aPrefs'],
                rPrefs: values['rPrefs'],
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then((response) => response.json())
        .then((json) => {
            console.log(json)
            // send user to search page
            navigate("/s/"+json['id'])
        });
    }


    return (
        <div className="Page">
            <Sidebar pages="search" id={props.id} sides={props.sides} />
            <div className="Content">
                <div className="Search">
                    <h1>VOYAJ</h1>
                    {
                    !searching ?
                    <>
                        <h3>Where are you headed?</h3>
                        <Form>
                            <InputGroup className="mb-3">
                                <Form.Control aria-label="From?" placeholder="From?" id="from" value={values['from']} onChange={handleValues} />
                                <Form.Control aria-label="To?" placeholder="To?" id="to" value={values['to']} onChange={handleValues}  />
                                <Form.Select
                                    name="source"
                                    onChange={handleSelect3}
                                    value={value3}
                                >
                                    <option value='' disabled selected>Attendees</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </Form.Select>
                                <Form.Select
                                    name="source"
                                    onChange={handleSelect2}
                                    value={value2}
                                >
                                    <option value='' disabled selected>Flight Class</option>
                                    <option value="economy">Economy</option>
                                    <option value="premium-economy">Premium Economy</option>
                                    <option value="business">Business</option>
                                    <option value="first">First</option>
                                </Form.Select>
                            </InputGroup>

                            <RangeDatePicker
                                startDate={dates['date']}
                                endDate={dates['date2']}
                                onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                minDate={new Date()}
                                maxDate={new Date(2025, 0, 1)}
                                dateFormat="MMM D, YYYY"
                                monthFormat="MMM YYYY"
                                startDatePlaceholder="Start Date"
                                endDatePlaceholder="End Date"
                                disabled={false}
                                className="my-own-class-name"
                                startWeekDay="monday"
                            />
                            <br />
                            <h3>Preferences</h3>
                        

                            <Row className="mb-3 Bottom-Row">
                                <Form.Group as={Col} controlId="hPrefs">
                                    <Form.Label>Hotel Preferences</Form.Label>
                                    <Form.Control placeholder="e.g. pet friendly" value={values['hPrefs']} onChange={handleValues} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="rPrefs">
                                    <Form.Label>Restaurant Preferences</Form.Label>
                                    <Form.Control placeholder="e.g. halal food" value={values['rPrefs']} onChange={handleValues} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="aPrefs">
                                    <Form.Label>Activity Preferences</Form.Label>
                                    <Form.Control placeholder="e.g. kid friendly" value={values['aPrefs']} onChange={handleValues} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="budget">
                                    <Form.Label>Budget</Form.Label>
                                    <Form.Select
                                        name="source"
                                        onChange={handleSelect}
                                        value={value}
                                    >
                                        <option value='' disabled selected>Budget</option>
                                        <option value="$">$</option>
                                        <option value="$$">$$</option>
                                        <option value="$$$">$$$</option>
                                        <option value="$$$$">$$$$</option>
                                    </Form.Select>
                                </Form.Group>
                                
                            </Row>

                            <div className="Search-Button-Container">
                                <Button variant="primary" size="lg" onClick={createSearch}>
                                    Search
                                </Button>
                            </div>
                        </Form>
                    </> : 
                    <div class="spinner-border text-primary" role="status">
                        {/* <span class="sr-only">Loading...</span> */}
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Search;